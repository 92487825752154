import React, { SyntheticEvent, useEffect, useState } from "react";
import {Button,  CheckboxProps,  Dropdown,  DropdownProps,  Header, Label, Message, Popup, Radio, Segment, Table } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _ColorsToChoose, _QimentDunJia } from "../../app/lib/CommonData";
import { _GetMonthName } from "../../app/lib/CommonFunctions";
import ForecastAction from "../../app/lib/ForecastAction";
import PersonalForecast from "../../app/lib/PersonalForecast";
import { DrawDateList, ForecastResult } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";
import AlertData from "./AlertData";
import ForecastResultDialog from "./ForecastResultDialog";
import ForecastSystem2DDialog from "./ForecastSystem2DDialog";
import ForecastSystem3DDialog from "./ForecastSystem3DDialog";
import ForecastVideo from "./ForecastVideo";

export default function Forecast(){
    const isQimentDunJiaOn = localStorage.getItem("isQimentDunJia");
    const [selectedDate, setSelectedDate] = useState("");
    const [forecastDate, setForecastDate] = useState<DrawDateList[]>([]);
    const {modalStore, userStore} = useStore();
    const [data,setData] = useState<ForecastResult[]>([]);
    const [qiMenDunJia, setQimenDunJia] = useState(isQimentDunJiaOn === "true" ? true : false);
    const [notifyDate, setNotifyDate] = useState(sessionStorage.getItem("lastNotifyDate") + "");


    const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        //drawResultStore.setLoadingInitial(true);
    
        setSelectedDate(option.value + "");
    
        //drawResultStore.loadDayResult();
        //drawResultStore.setLoadingInitial(false);
    
      }
    const openDialog = (system4D: string) => {
        localStorage.setItem("SelectedSystem4D", system4D);
        localStorage.setItem("SelectedDate", selectedDate);

        modalStore.openModal(<ForecastAction></ForecastAction>,"Action [" + system4D+"]","mini", null,()=>{});
    } 
    
    const monthNo = selectedDate === "" ? 0 : parseInt(selectedDate.split('-')[1]) - 1;
    const forecastYear = selectedDate === "" ? "" : selectedDate.split('-')[0];



    const openResultClick = () => {
        localStorage.setItem("SelectedDate", selectedDate);

        const title = "4DGM Forecast Result" + (isQimentDunJiaOn ? " PLUS" : "");
        modalStore.openModal(<ForecastResultDialog></ForecastResultDialog>, title,"large", null,()=>{})

    };

    const system3DClick = () => {
        localStorage.setItem("SelectedDate", selectedDate);

        const title = "4DGM Forecast System 3D";
        modalStore.openModal(<ForecastSystem3DDialog></ForecastSystem3DDialog>, title,"large",null, ()=>{})

    };

    const system2DClick = () => {
        localStorage.setItem("SelectedDate", selectedDate);

        const title = "4DGM Forecast System 2D";
        modalStore.openModal(<ForecastSystem2DDialog></ForecastSystem2DDialog>, title,"large", null,()=>{})

    };

    const handleChanged = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps)=>{
        setQimenDunJia(data.checked!);
        localStorage.setItem("isQimentDunJia", data.checked + "");
    }

    const openVideoHelp = () =>{
        modalStore.openModal(<ForecastVideo></ForecastVideo>, "Forecast Training","small", null, ()=>{})
      }
  
    useEffect(()=>{
        agent.Draw4DResult.getForecastDate().then((response)=>{
            setForecastDate(response);
            if (response.length > 0){
                setSelectedDate(response[0].draw_Date);

                const todayDate = new Date().toDateString();
                if (notifyDate !== null && todayDate === notifyDate) {
                    setNotifyDate(todayDate);
                    return;
                }
        
                setNotifyDate(todayDate);
                sessionStorage.setItem("lastNotifyDate", todayDate);

                setTimeout(() => {
                    agent.Draw4DResult.getAlertData().then((response)=>{
                        if (response.length > 0){
                            localStorage.setItem("alertDataNumbers", JSON.stringify(response));
                            modalStore.openModal(<AlertData></AlertData>, "Alert","small", null, ()=>{});
                        }
                    });
                }, 2000);                
            }
        });
    }, [notifyDate]);

    
    useEffect(()=>{
        if (selectedDate === "") return;

        agent.Draw4DResult.getForecast(selectedDate).then((response)=>{
            for(var i = 0; i < response.length;i++){
                response[i].system4ABCD_Color = _ColorsToChoose[Math.floor(Math.random()*_ColorsToChoose.length)];
                response[i].system4AABC_Color = _ColorsToChoose[Math.floor(Math.random()*_ColorsToChoose.length)];
                response[i].system4AABB_Color = _ColorsToChoose[Math.floor(Math.random()*_ColorsToChoose.length)];
                response[i].system4AAAB_Color = _ColorsToChoose[Math.floor(Math.random()*_ColorsToChoose.length)];

                response[i].systemABCD_Qimen = _QimentDunJia[Math.floor(Math.random()*_QimentDunJia.length)];
                response[i].systemAABC_Qimen = _QimentDunJia[Math.floor(Math.random()*_QimentDunJia.length)];
                response[i].systemAABB_Qimen = _QimentDunJia[Math.floor(Math.random()*_QimentDunJia.length)];
                response[i].systemAAAB_Qimen = _QimentDunJia[Math.floor(Math.random()*_QimentDunJia.length)];

            }

            setData(response);

        });

        
    },[qiMenDunJia, selectedDate]);


    if(!userStore.isLogged){
        return (<Header>Not Authorized</Header>);
    }
  

    return (
        <div style={{overflowX:"auto"}}>
        <PersonalForecast></PersonalForecast>    
        <Message warning>
        Remember to "Play Responsibly". Always punt within your own means and budget. Never over-spend and over-indulge in your betting. Play as a form of entertainment and most importantly enjoy the 4D games.
        </Message>

        <Table unstackable celled sortable>
            <Table.Header>
                <Table.Row key="hdr">
                    <Table.HeaderCell colSpan="2" rowSpan="2" textAlign="center">
                        <Radio onChange={handleChanged} toggle  checked={qiMenDunJia} label="Plus"></Radio>
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan="3" textAlign="center">
                        <Header as="h1">4DGM For {_GetMonthName(monthNo)+ " " + forecastYear} <br/>Forecast {qiMenDunJia ? " PLUS " : ""}</Header>
                        <Button size="medium" type="button" onClick={()=>system2DClick()}>System 2D</Button>
                        <Button size="medium" primary type="button" onClick={()=>openResultClick()}>Result</Button>
                        <Button size="medium" type="button" onClick={()=>system3DClick()}>System 3D</Button>
                        <Button size="medium"  data-tooltip="Show Video Training" positive icon="video" onClick={()=>{openVideoHelp()}}></Button>

                    </Table.HeaderCell>
                </Table.Row>   
                <Table.Row>
                    <Table.HeaderCell colSpan="3" textAlign="center">
                        <div style={{fontSize:"22px",marginTop:"10px",marginBottom:"10px"}}>
                        Forecast Date:
                        <Dropdown style={{marginLeft: '10px'}} compact 
                            onChange={handleDropDownSelect}
                            options={forecastDate.map(x=> ({key: x.draw_Date, text: x.draw_Date, value: x.draw_Date}))}
                            value = {selectedDate}
                    
                        />
                        </div>
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row key="hdr1">
                    <Table.HeaderCell textAlign="center">
                        #
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        ABCD
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        AABC
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        AABB
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        AAAB
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            {   data && data.length > 0 &&
                data.map((x,i)=>{
                return (
                    <Table.Row key={i}>
                        <Table.Cell textAlign="center">{i + 1}</Table.Cell>
                        <Table.Cell textAlign="center">
                            {qiMenDunJia ?
                                <p data-tooltip={"Click for more action [" + x.system4ABCD_Color + "]"} onClick={()=>openDialog(x.system4DABCD)}  style={{fontWeight:"bold", textAlign: "center", color:x.system4ABCD_Color, fontSize:"22px"}}>{x.system4DABCD}<br/> [{x.systemABCD_Qimen}]</p>
                                :
                                <p data-tooltip={"Click for more action"} onClick={()=>openDialog(x.system4DABCD)}  style={{fontWeight:"bold", textAlign: "center",  fontSize:"22px"}}>{x.system4DABCD}</p>
                            }
                            {x.system4DABCD_StrikeCount > 0 &&
                                <Popup content={x.system4DABCD_StrikeDescription} 
                                    trigger={<Label circular color={x.system4DABCD_StrikeTop3Count > 0 ? "red" : "blue"}>{x.system4DABCD_StrikeCount}</Label>}>
                                </Popup>
                           }
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {qiMenDunJia ?
                                <p data-tooltip={"Click for more action [" + x.system4AABC_Color + "]"} onClick={()=>openDialog(x.system4DAABC)}  style={{fontWeight:"bold", textAlign: "center", color:x.system4AABC_Color, fontSize:"22px"}}>{x.system4DAABC}<br/> [{x.systemAABC_Qimen}]</p>
                                :
                                <p data-tooltip={"Click for more action"} onClick={()=>openDialog(x.system4DAABC)}  style={{fontWeight:"bold", textAlign: "center",  fontSize:"22px"}}>{x.system4DAABC}</p>
                            }

                            {x.system4DAABC_StrikeCount > 0 && 
                                <Popup content={x.system4DAABC_StrikeDescription} 
                                    trigger={<Label  circular color={x.system4DAABC_StrikeTop3Count > 0 ? "red" : "blue"}>{x.system4DAABC_StrikeCount}</Label>}>
                                </Popup>
                           }
                            </Table.Cell>
                        <Table.Cell textAlign="center">
                            { qiMenDunJia ?
                                <p data-tooltip={"Click for more action [" + x.system4AABB_Color + "]"} onClick={()=>openDialog(x.system4DAABB)}  style={{fontSize:"22px",fontWeight:"bold", textAlign: "center", color:x.system4AABB_Color}}>{x.system4DAABB}<br/> [{x.systemAABB_Qimen}]</p>
                                :
                                <p data-tooltip={"Click for more action"} onClick={()=>openDialog(x.system4DAABB)}  style={{fontSize:"22px",fontWeight:"bold", textAlign: "center"}}>{x.system4DAABB}</p>
                            }
                            {x.system4DAABB_StrikeCount > 0 && 
                                <Popup content={x.system4DAABB_StrikeDescription} 
                                    trigger={<Label  circular color={x.system4DAABB_StrikeTop3Count > 0 ? "red" : "blue"}>{x.system4DAABB_StrikeCount}</Label>}>
                                </Popup>
                           }
                         </Table.Cell>
                        <Table.Cell textAlign="center">
                            {qiMenDunJia ?
                                <p data-tooltip={"Click for more action ["  + x.system4AAAB_Color + "]"} onClick={()=>openDialog(x.system4DAAAB)}  style={{fontSize:"22px", fontWeight:"bold", textAlign: "center", color:x.system4AAAB_Color}}>{x.system4DAAAB}<br/> [{x.systemAAAB_Qimen}]</p>
                                :
                                <p data-tooltip={"Click for more action"} onClick={()=>openDialog(x.system4DAAAB)}  style={{fontSize:"22px", fontWeight:"bold", textAlign: "center"}}>{x.system4DAAAB}</p>
                            }
                            {x.system4DAAAB_StrikeCount > 0 && 
                                
                                    <Popup content={x.system4DAAAB_StrikeDescription} 
                                        trigger={<Label  circular  color={x.system4DAAAB_StrikeTop3Count > 0 ? "red" : "blue"}>{x.system4DAAAB_StrikeCount}</Label>}>
                                    </Popup>
                                
                           }
                        </Table.Cell>
                    </Table.Row>
                )})
            }
            </Table.Body>

            <Table.Footer>
            
            {qiMenDunJia &&
            <Table.Row key="element">
                <Table.Cell colSpan="5">
                    Green is Wood element, Red is Fire element, Brown is Earth element, Silver is Metal element, Blue is Water element
                </Table.Cell>
            </Table.Row>
            }
            {qiMenDunJia &&
            <Table.Row key="direction">
                <Table.Cell colSpan="5">
                    S = South, SW = South West, West = West, NW = North West, N = North, NE = North East, E = East, SE = South East
                </Table.Cell>
            </Table.Row>
            }
            </Table.Footer>
        </Table>
        <Segment>
            <u>Advisory.</u><br/><br/> 
            This website and software are designed to provide lottery enthusiasts with 4D results, gaming data, information, lottery aids, etc. 

            All Users are encouraged to play the legalised lottery games as a form of entertainment and for sheer fun of it. 

            This site is NOT involved in or linked with any form of illegal lotteries or organised gambling which contravenes the Gaming Acts or any Laws of Singapore.
        </Segment>

        </div>

    )
}
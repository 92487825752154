import React, { useEffect, useState } from "react";
import { Table,  Header } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { _GetPrizeName, _GetSessionData } from "../../app/lib/CommonFunctions";
import { ForecastDrawResult, RecommendedDirectNumber } from "../../app/models/IDayResult";


export default function DetailEWinningBox(){
    const [data, setData] = useState<RecommendedDirectNumber[]>([]);

    function renderEWinBoxDetail(col: number){
        var rows:any[] = [];
        var columns:any[] = [];
        var counter = 0;

        for (var i = 0; i < data.length; i++){
            if (i % col === 0){
                columns = [];
                counter = 0;
            }
            columns.push(<td align="center">{data[i].number.indexOf("*") >= 0 ? <Header textAlign="center" color="red">{data[i].number.replace("*","")}</Header> : <Header  textAlign="center">{data[i].number}</Header>}</td>);                        
            if (i % col === col - 1){
                rows.push(<tr>{columns}</tr>)
            }
            counter++;
        }

            if (counter < 4){
                rows.push(<tr>{columns}</tr>)
            }

        return rows;
    }

    useEffect(()=>{
        const eWinBox = parseInt(localStorage.getItem("SelectedBox") + "");
        const selectedDate = localStorage.getItem("SelectedDate") + "";

        const rating = parseInt(_GetSessionData("EWinBox_Rating", 6));
        const drawType = _GetSessionData("EWinBox_DrawType","A");
        const directInterval = parseInt(_GetSessionData("EWinBox_DirectInterval",0));
        const systemInterval = parseInt(_GetSessionData("EWinBox_SystemInterval",0));
        const top3Interval = parseInt(_GetSessionData("EWinBox_Top3Interval",0));;
        const systemStatus = _GetSessionData("EWinBox_SystemStatus","0");
        const top3Status = _GetSessionData("EWinBox_Top3Status","0");
        const numberType = _GetSessionData("EWinBox_NumberType","0");

        agent.Draw4DResult.getDetailedEWinningBox(selectedDate, eWinBox, rating, drawType, directInterval,
                                    systemInterval, top3Interval, systemStatus, top3Status, numberType).then((response)=>{
            setData(response);
        });

        
    },[]);

    if (data.length === 0){
        return (<Header>No Data</Header>);
    }

    return(
        <div style={{textAlign:"center"}}>
            <Table unstackable celled style={{"borderCollapse":"collapse", "borderWidth":"4px",'borderColor':'red'}}>
                <Table.Header>
                    <Table.Cell colSpan="4">
                        # of direct numbers found: {data.length}
                    </Table.Cell>
                </Table.Header>
                <Table.Body>
                    {renderEWinBoxDetail(4)}
                    </Table.Body>
            </Table>
        </div>
    );
}
import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { DropdownProps, Grid, Header, Label,  Table } from "semantic-ui-react";
import { history } from "../..";
import agent from "../../app/api/agent";
import { _PrizeTypeOptions } from "../../app/lib/CommonData";
import LoadingComponent from "../../app/lib/LoadingComponent";
import MyDropDown from "../../app/lib/MyDropDown";
import { DigitPosition } from "../../app/models/IDayResult";
import { useStore } from "../../app/store/store";

export default observer( function ShowDigitPosition(){
    const [loading,setLoading] = useState(true);
    const [drawType, setDrawType] = useState("A");
    const [data, setData] = useState<DigitPosition[]>([]);    
    const [noOfMonth, setNoOfMonth] = useState("3");
    const {userStore} = useStore();

    if(!userStore.isLogged){
        history.push("/");
      }
  
    const monthOptions = [
        {   key : "1", 
            text : "1 Week",
            value: "1"
        },
        {
            key: "3",
            text: "3 months",
            value: "3"
        },
        {
            key: "6",
            text: "6 months",
            value: "6"
        },
        {
            key: "12",
            text: "1 Year",
            value: "12"
        },
        {
            key: "24",
            text: "2 Years",
            value: "24"
        }
    ];

    const handleDropDownSelect = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setLoading(true);
        setDrawType(option.value + "");
    }

    const handleDropDownSelectMonth = (e: SyntheticEvent<any, Event>, option: DropdownProps)=>{
        setLoading(true);
        setNoOfMonth(option.value + "");
    }

    

    useEffect(()=>{
        agent.Draw4DResult.getDigitPosition(drawType, noOfMonth).then((response)=>{

            setData(response);
            setLoading(false);
            //setLoaded(true);
            //isLoading = false;
        });
        
    },[ drawType, noOfMonth]); 



    if (loading){
        return (<LoadingComponent></LoadingComponent>); 
    }

    return (
          <div style={{overflowX:"auto"}}>
              
            <Table celled  sortable unstackable >
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan="5">
                            <Grid centered divided columns={2}>
                                <Grid.Column textAlign='center'>
                                        <MyDropDown  zIndex="20"
                                            onChange = {handleDropDownSelect}
                                            placeholder='Select Draw Type'
                                            options={_PrizeTypeOptions}
                                            defaultValue = {drawType}
                                            toolTip="Select Draw Type"
                                            toolTipDirection="top center">
                                        </MyDropDown>
                                </Grid.Column>
                                <Grid.Column textAlign='center'>
                                    <MyDropDown zIndex="20"
                                        onChange = {handleDropDownSelectMonth}
                                        placeholder='Select No Of Month'
                                        options={monthOptions}
                                        defaultValue = {noOfMonth}
                                        toolTip="Select No Of Month"
                                        toolTipDirection="top center">
                                    </MyDropDown>
                                </Grid.Column>
                                </Grid>
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell colSpan="5" textAlign="center">
                            Digit Position
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell textAlign="center">1st</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">2nd</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">3rd</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">4th</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data && data.length > 0 &&
                data.map((x)=>{
                    return(
                        <Table.Row>
                              <Table.Cell textAlign="center">
                                <Label data-tooltip={x.position1 +", No of Strike: of 1st Position" + x.hitCount1}  circular size={"medium"} color={"blue"}>{x.hitCount1}
                                <Header color={"yellow"} as="h1">{x.position1}</Header>
                                </Label>
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <Label data-tooltip={x.position2 + ", No of Strike of 2nd Position: " + x.hitCount2} circular size={"medium"} color={"blue"}>
                                    {x.hitCount2}
                                    <Header color={"yellow"} as="h1">{x.position2}</Header>
                                </Label>
                            </Table.Cell >
                            <Table.Cell textAlign="center">
                                <Label  data-tooltip={x.position3 +", No of Strike of 3rd Position: " + x.hitCount3} circular size={"medium"} color={"blue"}>
                                    {x.hitCount3}
                                    <Header color={"yellow"} as="h1">{x.position3}</Header>
                                </Label>
                    
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <Label data-tooltip={x.position4 + ", No of Strike of 4th Position: " + x.hitCount4} circular size={"medium"} color={"blue"}>
                                    {x.hitCount4}
                                    <Header color={"yellow"} as="h1">{x.position4}</Header>
                                    </Label>
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <Label data-tooltip={x.positionAll + ", No of Strike of All Position: " + x.hitAll}  size={"medium"} color={"orange"}>
                                    {x.hitAll}
                                    <Header as="h1"><span color="white">{x.positionAll}</span></Header>
                                    </Label>
                            </Table.Cell>
  
                        </Table.Row>
                    );
                })
            }
                
            </Table.Body>
            </Table>
        </div>
    );
})
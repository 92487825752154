import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { history } from "../..";
import { AlertData, AlertRatingAndStatus, Analysis3DResult, AnalysisDirectResult, AnalysisResult, AnalysisResultNew, AnalysisSystem3DResult, Best2DResult, Best3DResult, DayResult, DigitPosition, DigitPositionHistoryResult, DirectIntervalHistoryResult, DirectNumberResult, DrawDateList, DrawDetailResult, DrawList, eWinBoxQuery, eWinningBoxResult, ExpressDirectResult, FeedbackMessage, ForecastDrawResult, ForecastMethodResult, ForecastResult, ForecastResultDirectNumber, ForecastSystem2D, ForecastSystem3D, GraphicResult, Interval, IntervalResult, MonthDayResult, MonthlyAllRankHistory, MonthlyEWinBox, MyDropDownValue, MyDropDownValueString,  NumberSearch3DTo4D, NumberSearchResult, PageResult, PatternHistory, PatternResult, PersonalForecastNumber, QueryConditionResult, QueryScoreResult, RAnalysisResult, RatingHistory, RecommendedDirectNumber, StatusHistory, submitEWinBox, Sum1D, Sum4DAll, System3DAllRankPattern, System3DAllRankPerMonthPattern, System3DTop3Pattern, System3DTop3PerMonthPattern, TempWinningBox, Top3RankHistory, UserList, WatchListResult } from "../models/IDayResult";
import { changePasswordFormValues, resetPasswordFormValues, User, userFormValues, userRegistrationValues, userRenewSubscription, WeeklyForecastValues } from "../models/user";
import { store } from "../store/store";


axios.defaults.headers['Access-Control-Allow-Methods'] = "DELETE,POST,GET,OPTIONS";
axios.defaults.headers['Access-Control-Allow-Credentials'] = "true";
axios.defaults.headers['Access-Control-Allow-Origin'] = 'https://4dgrandmaster.com';
axios.defaults.headers["Access-Control-Allow-Headers"] = "Origin,Authorization,X-Auth-Token";
axios.defaults.withCredentials = true;

//axios.defaults.baseURL = "https://sg4api.4dgrandmaster.com/api";
// Current
axios.defaults.baseURL = "https://api.4dgrandmaster.com/api";

//axios.defaults.baseURL = "https://localhost:44385/api";

axios.interceptors.request.use(config => {
    const token = store.commonStore._token;
    if (token){
        config.headers.Authorization = `bearer ${token}`
    }
    return config;
})

axios.interceptors.response.use(async response => {
    return response;
}, (error:AxiosError)=>{
    if(!error.response || !error.response.data){
        if (error.response && error.response?.status === 401){
            store.userStore.logout();

            return;
        }

        return Promise.reject(error);
    }
    const {data, status, config, headers} = error.response!;


    switch(status){
        case 400:
            if (typeof data === "string") {
                toast.error(data);
                return;
            }
            if(config.method === "get" && data.errors && data.errors.hasOwnProperty("id")){
                history.push("/not-found");
                return;
            }
            if(data.errors){
                const modelStateErrors = [];
                for(const key in data.errors){
                    if(data.errors[key]){
                        modelStateErrors.push(data.errors[key])
                    }
                }
                throw modelStateErrors.flat();
            }
            break;
        case 401:
            if (headers["WWW-Authenticate"].startsWith('Bearer error="invalid_token"')){
                store.userStore.logout();

                return Promise.reject(error);
            }
            //toast.error("Unauthorised !");
            break;
        case 404:
            history.push('/not-found');
            break;
        case 500:
            store.commonStore.setServerError(data);
            history.push("/server-error");
            break;
    }

    return Promise.reject(error);
});
const responseBody = <T>(response: AxiosResponse<T>) => {
    const errorMessage =  { code : 403, message : "Wrong Response" };

    if (!response || !response.data || response.data === undefined) throw errorMessage;  
    return response.data;
}

const config1 = {
    headers: {    
        'content-type': 'application/x-www-form-urlencoded',    
    }    
}

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, data: any) => axios.post<T>(url, data).then(responseBody),
    put: <T>(url: string, data: any) => axios.put<T>(url, data).then(responseBody),
    del: <T> (url: string, data: any) => axios.delete<T>(url, data).then(responseBody),
    postWithFile: <T>(url: string, data: FormData) => axios.post<T>(url, data, config1).then(responseBody),
    
}

const Draw4DResult = {
    list: (dd : string)=> {
        if (dd === ""){
            dd = "-";
        }
     
        return requests.get<DayResult>(`/DrawResult/GetLastDrawDate/${dd}`)
    },
    getDetailedEWinningBox:(selectedDate: string, eWinBox: number, rating: number,drawType: string,
                            directInterval: number, systemInterval: number, top3Interval: number, 
                            systemStatus:string, top3Status:string, numberType: string) => 
    requests.get<RecommendedDirectNumber[]>(`/DrawResult/getDetailedEWinningBox/${selectedDate}/${eWinBox}/${rating}/${drawType}/${directInterval}/${systemInterval}/${top3Interval}/${systemStatus}/${top3Status}/${numberType}`),    
    getMonthlyEWinBoxDrawResult:(selectedDate: string)=> requests.get<eWinningBoxResult[]>(`/DrawResult/GetMonthlyEWinBoxDrawResult/${selectedDate}`),
    getMonthlyEWinbox: (isOriginal: boolean, selectedDate: string)=> requests.get<MonthlyEWinBox>(`/DrawResult/GetMonthlyEWinbox/${isOriginal}/${selectedDate}`),
    getDailyEWinbox: (isOriginal: boolean, selectedDate: string) => requests.get<MonthlyEWinBox>(`/DrawResult/GetDailyEWinbox/${isOriginal}/${selectedDate}`),
    getDailyEWinBoxDrawResult: (selectedDate: string) => requests.get<eWinningBoxResult[]>(`/DrawResult/GetDailyEWinBoxDrawResult/${selectedDate}`),
    getRAnalysisResult: (drawType: string, system3D: string, drawPattern: string, page: number, column: string, direction: string, pageSize: number) => requests.get<RAnalysisResult[]>(`/DrawResult/GetRAnalysisResult/${drawType}/${system3D}/${drawPattern}/${page}/${column}/${direction}/${pageSize}`),
    getSystem4DDirectSquare: (selectedDate: string, winBox: number) => requests.get<MyDropDownValueString[]>(`/DrawResult/getSystem4DDirectSquare/${selectedDate}/${winBox}`),
    getForecast2D: (forecastDate: string, drawType: string, status: string,  column:string, direction: string, drawType2D: string ) => requests.get<ForecastSystem2D[]>(`/DrawResult/GetForecast2D/${forecastDate}/${drawType}/${status}/${column}/${direction}/${drawType2D}`),
    getForecast3D: (forecastDate: string, drawType: string, status: string,  column:string, direction: string, drawType3D: string ) => requests.get<ForecastSystem3D[]>(`/DrawResult/GetForecast3D/${forecastDate}/${drawType}/${status}/${column}/${direction}/${drawType3D}`),
    getWinningBox3D: (forecastDate: string, drawType: string, status: string,  column:string, direction: string, drawType3D: string, boxNo: string ) => requests.get<ForecastSystem3D[]>(`/DrawResult/GetWinningBox3D/${forecastDate}/${drawType}/${status}/${column}/${direction}/${drawType3D}/${boxNo}`),
    getWinningBox2D: (forecastDate: string, drawType: string, status: string,  column:string, direction: string, drawType2D: string, boxNo: string ) => requests.get<ForecastSystem2D[]>(`/DrawResult/GetWinningBox2D/${forecastDate}/${drawType}/${status}/${column}/${direction}/${drawType2D}/${boxNo}`),
    getExpressDirect:(number: string) => requests.get<ExpressDirectResult>(`/DrawResult/GetExpressDirect/${number}`),
    get3DInfo:(number: string, column: string, direction:string) => requests.get<NumberSearch3DTo4D[]>(`/DrawResult/Get3DInfo/${number}/${column}/${direction}`),
    getDigitPosition: (drawType: string, noOfMonth:string) => requests.get<DigitPosition[]>(`/DrawResult/GetDigitPosition/${drawType}/${noOfMonth}`),
    getGraphicsResultTop3: (number: string) => requests.get<GraphicResult[]>(`/DrawResult/GetGraphicsResultTop3/${number}`),
    getGraphicsResult: (number: string) => requests.get<GraphicResult[]>(`/DrawResult/GetGraphicsResult/${number}`),
    getGraphicsSystem3DResult: (number: string) => requests.get<GraphicResult[]>(`/DrawResult/getGraphicsSystem3DResult/${number}`),
    getGraphicsTop33DResult: (number: string) => requests.get<GraphicResult[]>(`/DrawResult/getGraphicsTop33DResult/${number}`),
    getAnalysis2D: (drawType: string, column:string, direction:string, page: number, rowPerPage: number) => requests.get<Analysis3DResult[]>(`/DrawResult/GetAnalysis2D/${drawType}/${column}/${direction}/${page}/${rowPerPage}`),
    getRecommendedDirectNumber:(number: string)=> requests.get<RecommendedDirectNumber[]>(`/DrawResult/GetDirectNumber/${number}`),
    getAnalysis3D: (drawType: string, column:string, direction:string, page: number,  rowPerPage: number) => requests.get<Analysis3DResult[]>(`/DrawResult/GetAnalysis3D/${drawType}/${column}/${direction}/${page}/${rowPerPage}`),
    getAnalysis3DFrom2D: (system2D: string,  column:string, direction:string) => requests.get<Analysis3DResult[]>(`/DrawResult/GetAnalysis3DFrom2D/${system2D}/${column}/${direction}`),
    getAnalysis3DFromTypeAndRange: (type3D: string, range3D:string, drawType: string, column:string, direction:string, page: number,  rowPerPage: number) => requests.get<AnalysisSystem3DResult[]>(`/DrawResult/GetAnalysis3DFromTypeAndRange/${type3D}/${range3D}/${drawType}/${column}/${direction}/${page}/${rowPerPage}`),
    getDrawResult:(isSystem : boolean, numberToSearch: string, page: number, drawType: string, column:string, direction:string)=> requests.get<DrawList[]>(`/DrawResult/GetDrawListByNumber/${numberToSearch}/${isSystem}/${page}/${drawType}/${column}/${direction}`), 
    getSummaryByInterval: (numberToRetrieve: string, isSystem: boolean, prizeType: string)=> requests.get<DirectNumberResult[]>(`/DrawResult/GetSummaryByInterval/${isSystem}/${numberToRetrieve}/${prizeType}`),
    getSummaryByStrike: (numberToRetrieve: string, isSystem: boolean, prizeType: string)=> requests.get<DirectNumberResult[]>(`/DrawResult/GetSummaryByStrike/${isSystem}/${numberToRetrieve}/${prizeType}`),
    searchNumber: (isSystem : boolean, numberToSearch: string)=> requests.get<NumberSearchResult[]>(`/DrawResult/FindNumber/${isSystem}/${numberToSearch}`),
    getDrawDates: ()=> requests.get<DrawDateList[]>("/DrawResult/GetDrawDate"),
    getForecastMethodResult: (drawType:string, column: string, direction: string, page: number,  rowPerPage: number)=> requests.get<ForecastMethodResult[]>(`/DrawResult/GetForecastMethodResult/${drawType}/${column}/${direction}/${page}/${rowPerPage}`),
    getAnalysis: (drawType:string, column: string, direction: string, page: number, status: string, rowPerPage: number, rating: number)=> requests.get<AnalysisResult[]>(`/DrawResult/GetAnalysis/${drawType}/${column}/${direction}/${page}/${status}/${rowPerPage}/${rating}`),
    getAnalysisDirectByRange: (drawType:string, column: string, direction: string, page: number, status: string, rowPerPage: number, rating: number, type4D:string, range4D: string)=> requests.get<AnalysisDirectResult[]>(`/DrawResult/GetDirectAnalysisByRange/${drawType}/${column}/${direction}/${page}/${status}/${rowPerPage}/${rating}/${type4D}/${range4D}`),
    getAnalysisByRank: (drawType:string, column: string, direction: string, page: number, status: string, rowPerPage: number, rating: number, type4D:string, range4D: string)=> requests.get<AnalysisResult[]>(`/DrawResult/GetAnalysisByRank/${drawType}/${column}/${direction}/${page}/${status}/${rowPerPage}/${rating}/${type4D}/${range4D}`),
    getAnalysisBySumRoot: (drawType:string, column: string, direction: string, page: number, status: string, rowPerPage: number, rating: number, type4D:string, range4D: string)=> requests.get<AnalysisResult[]>(`/DrawResult/GetAnalysisBySumRoot/${drawType}/${column}/${direction}/${page}/${status}/${rowPerPage}/${rating}/${type4D}/${range4D}`),
    getAnalysisByRange: (drawType:string, column: string, direction: string, page: number, status: string, rowPerPage: number, rating: number, type4D:string, range4D: string)=> requests.get<AnalysisResult[]>(`/DrawResult/GetAnalysisByRange/${drawType}/${column}/${direction}/${page}/${status}/${rowPerPage}/${rating}/${type4D}/${range4D}`),
    getAnalysisBySystem3DStatus: (drawType:string, column: string, direction: string, page: number, status: string, rowPerPage: number, rating: number, statusNumber:string, statusType: string, position: string)=> requests.get<AnalysisResultNew[]>(`/DrawResult/GetAnalysisBySystem3DStatus/${drawType}/${column}/${direction}/${page}/${status}/${rowPerPage}/${rating}/${statusNumber}/${statusType}/${position}`),
    getAnalysisByMonth: (drawType:string, column: string, direction: string, page: number, status: string, rowPerPage: number,  rating: number)=> requests.get<AnalysisResult[]>(`/DrawResult/GetAnalysisByMonth/${drawType}/${column}/${direction}/${page}/${status}/${rowPerPage}/${rating}`),
    getPageBy4DNumber: (numberToSearch: string, column: string, direction:string, pattern: string, status: string, rowPerPage: number, rating: number)=> requests.get<PageResult>(`/DrawResult/GetPageFrom4DNumber/${numberToSearch}/${column}/${direction}/${pattern}/${status}/${rowPerPage}/${rating}`),
    getPageBy4DNumberByRank: (numberToSearch: string, column: string, direction:string, pattern: string, status: string, rowPerPage: number, rating: number, type4D: string, range4D: string)=> requests.get<PageResult>(`/DrawResult/GetPageFrom4DNumberByRank/${numberToSearch}/${column}/${direction}/${pattern}/${status}/${rowPerPage}/${rating}/${type4D}/${range4D}`),
    getPageBy4DNumberBySumRoot: (numberToSearch: string, column: string, direction:string, pattern: string, status: string, rowPerPage: number, rating: number, type4D: string, range4D: string)=> requests.get<PageResult>(`/DrawResult/GetPageFrom4DNumberBySumRoot/${numberToSearch}/${column}/${direction}/${pattern}/${status}/${rowPerPage}/${rating}/${type4D}/${range4D}`),
    getPageBy4DNumberByRange: (numberToSearch: string, column: string, direction:string, pattern: string, status: string, rowPerPage: number, rating: number, type4D: string, range4D: string)=> requests.get<PageResult>(`/DrawResult/GetPageFrom4DNumberByRange/${numberToSearch}/${column}/${direction}/${pattern}/${status}/${rowPerPage}/${rating}/${type4D}/${range4D}`),
    getPageBy4DNumberBySystem3DStatus: (numberToSearch: string, column: string, direction:string, pattern: string, status: string, rowPerPage: number, rating: number, statusNumber: string, statusType: string, position: string)=> requests.get<PageResult>(`/DrawResult/GetPageFrom4DNumberBySystem3DStatus/${numberToSearch}/${column}/${direction}/${pattern}/${status}/${rowPerPage}/${rating}/${statusNumber}/${statusType}/${position}`),
    getPageForForecastMethod: (numberToSearch: string, column: string, direction:string, pattern: string,  rowPerPage: number)=> requests.get<PageResult>(`/DrawResult/GetPageForForecastMethod/${numberToSearch}/${column}/${direction}/${pattern}/${rowPerPage}`),
    getPageBy4DNumberMonthly: (numberToSearch: string, column: string, direction:string, pattern: string, status: string, rowPerPage: number, rating: number)=> requests.get<PageResult>(`/DrawResult/GetPageFrom4DNumberMonthly/${numberToSearch}/${column}/${direction}/${pattern}/${status}/${rowPerPage}/${rating}`),
    getPageBy3DNumber: (numberToSearch: string, column: string, direction:string, pattern: string, rowPerPage: number)=> requests.get<PageResult>(`/DrawResult/GetPageFrom3DNumber/${numberToSearch}/${column}/${direction}/${pattern}/${rowPerPage}`),
    getPageBy3DNumberCommon: (numberToSearch: string, column: string, direction:string,  rowPerPage: number, type3D: string, range3D: string, drawType:string)=> requests.get<PageResult>(`/DrawResult/GetPageFrom3DNumberCommon/${numberToSearch}/${column}/${direction}/${rowPerPage}/${type3D}/${range3D}/${drawType}`),
    getPageBy2DNumber: (numberToSearch: string, column: string, direction:string, pattern: string, rowPerPage: number)=> requests.get<PageResult>(`/DrawResult/GetPageFrom2DNumber/${numberToSearch}/${column}/${direction}/${pattern}/${rowPerPage}`),
    getStatusHistory: (prizeType: string, noOfDraw: number, statusType: string, drawType: string) => requests.get<StatusHistory[]>(`/DrawResult/GetStatusHistory/${prizeType}/${noOfDraw}/${statusType}/${drawType}`),
    getStatusHistory3D: (prizeType: string, noOfDraw: number, statusType: string, drawType: string, position: number) => requests.get<StatusHistory[]>(`/DrawResult/GetStatusHistory3D/${prizeType}/${noOfDraw}/${statusType}/${drawType}/${position}`),
    getRatingHistory: (prizeType: string, noOfDraw: number, page: number, forecastType: string, drawType: string) => requests.get<RatingHistory[]>(`/DrawResult/GetRatingHistory/${prizeType}/${noOfDraw}/${page}/${forecastType}/${drawType}`),
    getPatternHistory: (drawType: string, noOfDraw: number, page: number) => requests.get<PatternHistory[]>(`/DrawResult/GetPatternHistory/${drawType}/${noOfDraw}/${page}`),
    getDigitPatternHistory: (drawType: string, digitPosition: number) => requests.get<DigitPositionHistoryResult[]>(`/DrawResult/GetDigitPositionHistory/${drawType}/${digitPosition}`),
    getSum4DNumbersHistory: (prizeType: string, noOfDraw: number, drawType: string) => requests.get<Sum1D[]>(`/DrawResult/GetSum4DNumberHistory/${prizeType}/${noOfDraw}/${drawType}`),
    getSum4DAllNumbersHistory: (prizeType: string, noOfDraw: number, drawType: string) => requests.get<Sum4DAll[]>(`/DrawResult/GetSum4DNumberAllHistory/${prizeType}/${noOfDraw}/${drawType}`),
    getSum3DHistory: (prizeType: string, noOfDraw: number, drawType: string, intervalType: string) => requests.get<PatternResult[]>(`/DrawResult/GetSum3DHistory/${prizeType}/${noOfDraw}/${drawType}/${intervalType}`),
    getSum2DHistory: (prizeType: string, noOfDraw: number, drawType: string, intervalType: string) => requests.get<PatternResult[]>(`/DrawResult/GetSum2DHistory/${prizeType}/${noOfDraw}/${drawType}/${intervalType}`),
    getIntervalHistory: (prizeType: string, noOfDraw: number, drawType: string, intervalType: string) => requests.get<PatternResult[]>(`/DrawResult/GetIntervalHistory/${prizeType}/${noOfDraw}/${drawType}/${intervalType}`),
    getDirectIntervalHistory: (prizeType: string, noOfDraw: number, drawType: string) => requests.get<DirectIntervalHistoryResult[]>(`/DrawResult/GetDirectIntervalHistory/${prizeType}/${noOfDraw}/${drawType}`),
    getTop3IntervalHistory: (noOfDraw: number, drawType: string) => requests.get<DirectIntervalHistoryResult[]>(`/DrawResult/GetTop3IntervalHistory/${noOfDraw}/${drawType}`),
    getIntervalCombination3Top3History: (noOfDraw: number, drawType: string, systemType: string, prizeType: string) => requests.get<PatternResult[]>(`/DrawResult/GetIntervalCombination3Top3History/${noOfDraw}/${drawType}/${systemType}/${prizeType}`),
    getMonthDayResult:(month:number,day:number,prize:string, dayPart: number, drawType: string, status: string, column: string, direction:string) => requests.get<MonthDayResult[]>(`/DrawResult/GetMonthDay/${month}/${day}/${prize}/${dayPart}/${drawType}/${status}/${column}/${direction}`),
    postQueryNumber: (data: QueryConditionResult) => requests.post<IntervalResult[]>(`/DrawResult/PostQueryNumber`, data),
    postScoringNumber: (data: QueryConditionResult) => requests.post<QueryScoreResult[]>(`/DrawResult/PostScoringNumber`, data),
    getAllRankHistory: (drawType: string, noOfDraw: number, prizeCode: string, rankType:string) => requests.get<PatternResult[]>(`/DrawResult/GetAllRankHistory/${drawType}/${noOfDraw}/${prizeCode}/${rankType}`),
    getTop3RankHistory: (drawType: string, noOfDraw: number, prizeCode: string) => requests.get<Top3RankHistory[]>(`/DrawResult/GetTop3RankHistory/${drawType}/${noOfDraw}/${prizeCode}`),
    getMonthlyAllRankHistory: (drawType: string, noOfDraw: number, prizeCode: string) => requests.get<MonthlyAllRankHistory[]>(`/DrawResult/GetMonthlyAllRankHistory/${drawType}/${noOfDraw}/${prizeCode}`),
    getForecast:( selectedDate:string)=> requests.get<ForecastResult[]>(`/DrawResult/GetForecast/${selectedDate}`),
    getForecastDirectNumber:(system4D: string, selectedDate: string)=> requests.get<ForecastResultDirectNumber[]>(`/DrawResult/GetForecastDirectNumber/${system4D}/${selectedDate}`),
    getDetailResultDate:()=> requests.get<DrawDateList[]>(`/DrawResult/GetForecastDate/Detail`),
    getForecastDate:()=> requests.get<DrawDateList[]>(`/DrawResult/GetForecastDate/Forecast`),
    getWinningDate:(eWinBoxNumber: string)=> requests.get<DrawDateList[]>(`/DrawResult/GetForecastDate/${eWinBoxNumber}`),
    getForecastDrawResult:(selectedDate: string)=> requests.get<ForecastDrawResult[]>(`/DrawResult/GetForecastDrawResult/${selectedDate}`),
    getWinBoxDrawResult:(selectedDate: string, eWinBox: string)=> requests.get<ForecastDrawResult[]>(`/DrawResult/GetWinBoxDrawResult/${selectedDate}/${eWinBox}`),
    getBest3D:(numberType:string, systemInRange:string, top3InRange: string, column: string, direction: string, type3D: string) => requests.get<Best3DResult[]>(`/DrawResult/GetBest3D/${numberType}/${systemInRange}/${top3InRange}/${column}/${direction}/${type3D}`),
    getBest2D:(numberType:string, systemInRange:string, top3InRange: string, column: string, direction: string, type2D: string) => requests.get<Best2DResult[]>(`/DrawResult/GetBest2D/${numberType}/${systemInRange}/${top3InRange}/${column}/${direction}/${type2D}`),
    getDirect4D:(system4D: string) => requests.get<DirectNumberResult[]>(`/DrawResult/GetDirectNumber/${system4D}`),
    get3DAllRankPattern:(noOfDraw: number, drawType: string, prizeType: string) => requests.get<System3DAllRankPattern[]>(`/DrawResult/Get3DAllRankPattern/${noOfDraw}/${drawType}/${prizeType}`),
    get3DTop3Pattern:(noOfDraw: number, drawType: string, prizeType: string) => requests.get<System3DTop3Pattern[]>(`/DrawResult/Get3DTop3Pattern/${noOfDraw}/${drawType}/${prizeType}`),
    get3DAllRankPerMonthPattern:(noOfDraw: number, drawType: string, prizeType: string) => requests.get<System3DAllRankPerMonthPattern[]>(`/DrawResult/Get3DAllRankPerMonthPattern/${noOfDraw}/${drawType}/${prizeType}`),
    get3DTop3PerMonthPattern:(noOfDraw: number, drawType: string, prizeType: string) => requests.get<System3DTop3PerMonthPattern[]>(`/DrawResult/Get3DTop3PerMonthPattern/${noOfDraw}/${drawType}/${prizeType}`),
    getForecastByUser:(userName: string) => requests.get<PersonalForecastNumber[]>(`/DrawResult/GetForecastByUser/${userName}`),
    getDetailResult: (drawDate: string) => requests.get<DrawDetailResult[]>(`/DrawResult/GetDetailResult/${drawDate}`),
    getWinningBox:(eWinBox: eWinBoxQuery )=>requests.post<TempWinningBox[]>(`/DrawResult/getWinningBox`, eWinBox),
    getInterval:(drawType: string) => requests.get<Interval[]>(`/DrawResult/getInterval/${drawType}`),
    getOriginalWinningBox:(forecastDate:string, isOriginal: number, winBox: number) => requests.get<RecommendedDirectNumber[]>(`/DrawResult/getOriginalWinningBox/${forecastDate}/${isOriginal}/${winBox}`),
    getHistoryRecommendedNumber:(system4D: string, selectedDate: string) => requests.get<RecommendedDirectNumber[]>(`/DrawResult/getHistoryDirectNumber/${system4D}/${selectedDate}`),
    getQueryNumberParameter:(drawType: string) => requests.get<QueryConditionResult>(`/DrawResult/getQueryNumberParameter/${drawType}`),
    getExistingWatchListCategory:() => requests.get<MyDropDownValue[]>("/DrawResult/getExistingWatchListCategory"),
    insertWatchListCategory:(categoryValue: MyDropDownValue) => requests.post<MyDropDownValue>(`/DrawResult/insertWatchListCategory`, categoryValue),
    getWatchListNumbers:(categoryID: number, columnName: string, direction: string) => requests.get<WatchListResult[]>(`/DrawResult/getWatchListNumbers/${categoryID}/${columnName}/${direction}`),
    addToCategory:(categoryID: number, system4D: string) => requests.get<boolean>(`/DrawResult/addToCategory/${categoryID}/${system4D}`),
    deleteCategory:(categoryID: number) => requests.get<MyDropDownValue[]>(`/DrawResult/deleteCategory/${categoryID}`),
    deleteFromCategory:(categoryID: number, system4D: string) => requests.get<boolean>(`/DrawResult/deleteFromCategory/${categoryID}/${system4D}`),
    editWatchListCategory: (data: MyDropDownValue) => requests.post<boolean>(`/DrawResult/editCategory`, data),
    updateAlert: (categoryID: number, system4D: string, isChecked: boolean) => requests.post<boolean>(`/DrawResult/updateAlert`, { categoryID: categoryID, system4D: system4D, isChecked: isChecked }),
    updateAlertWithData: (categoryID: number, system4D: string, status: string, rating: string) => requests.post<boolean>(`/DrawResult/updateAlertWithData`, { categoryID: categoryID, system4D: system4D, status: status, rating: rating }),
    getAlertSetting: (categoryID: number, system4D: string) => requests.get<AlertRatingAndStatus>(`/DrawResult/getAlertSetting/${categoryID}/${system4D}`),
    getAlertData: () => requests.get<AlertData[]>(`/DrawResult/getAlertData/`),
    checkWatchlistResult:(categoryID: number, noOfDraw: number) => requests.get<any>(`/DrawResult/checkWatchlistResult/${categoryID}/${noOfDraw}`)
}

const Account = {
    submitWinBox:(data: submitEWinBox )=>requests.post<string>('/account/SubmitEWinbox',data),
    changeToMonthlyForecast: () => requests.post<string>('/account/InsertMonthlyForecastJob', {}), 
    deleteMember: (values: {email: string; deviceID: string; error: null}) => requests.post<string>(`/account/deleteMember`, values),
    submitForecast:(values: WeeklyForecastValues, isNew: boolean, isChanged: boolean) => requests.post<boolean>(`/account/submitForecast/${isNew}/${isChanged}`, values),
    current: ()=> requests.get<User>("/account"),
    login: (user: userFormValues) => requests.post<User>("/account/login", user),
    resetActualPassword: (user: resetPasswordFormValues) => requests.post<string>("/account/resetActualPassword", user),
    resetPassword: (user: userFormValues) => requests.post<string>("/account/resetPassword", user),
    changePassword: (user: changePasswordFormValues) => requests.post<string>("/account/ChangePassword", user),
    register: (user: userRegistrationValues) => requests.post<string>("/account/RegisterMember", user),
    renewSubscription: (user: userRenewSubscription) => requests.post<string>("/account/RenewSubscription", user),
    changeDevice: (user: userRegistrationValues) => requests.post<string>("/account/ChangeDevice", user),
    refreshToken: () => requests.post<User>("/account/refreshToken", {}),
    logout: () => requests.post("/account/logout",{}),
    changeDisplayName:(user: FormData)=>requests.postWithFile<User>("/account/ChangeName",user),
    sendMessage:(feedback: FeedbackMessage)=>requests.post<void>("/account/SendFeedback",feedback),
    getUserList:(column: string, direction: string)=>requests.get<UserList[]>(`/account/getUserList/${column}/${direction}`),
    getExistingForecast:(userID: string)=>requests.get<WeeklyForecastValues>(`/account/getExistingForecastByUser/${userID}`),
    deleteExistingForecast:(userID: string)=>requests.get<string>(`/account/deleteExistingForecastByUser/${userID}`),
    changeRole:()=> requests.post("/account/ChangeRole",{}),
    getRating:(system4D: string) => requests.get<number>(`/account/getRating/${system4D}`)
}

const Photo = {
    getPhoto: (imageName:string) => requests.get<any>(`/photo/GetPhoto/${imageName}`),
    getPdf: (pdfFile:string) => requests.get<any>(`/photo/GetPDF/${pdfFile}`),
    getURL: (fileName:string) => axios.defaults.baseURL + "/Photo/GetPhoto/" + fileName,
}

const agent = {
    Draw4DResult,
    Account,
    Photo
}

export default agent;
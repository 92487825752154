import React from 'react';
import './App.css';
import { Segment } from 'semantic-ui-react';
import FourDNavBar from './FourDNavBar';
import { observer } from 'mobx-react-lite';
import HomePage from '../../feature/home/HomePage';
import { Route, Switch, useLocation } from 'react-router-dom';
import Analysis from '../../feature/analysis/analysis';
import AnalysisByMonth from '../../feature/analysisbymonth/analysisbymonth';
import ViewEditNumber from '../../feature/viewnumber/viewsearnumber';
import Analysis3D from '../../feature/analysis3d/analysis3d';
import Analysis2D from '../../feature/analysis2d/analysis2d';
import { ToastContainer } from 'react-toastify';
import NotFound from '../lib/notfound';
import ServerError from '../lib/servererror';
import digitposition from '../../feature/digitposition/digitposition';
import view4dfrom3d from '../../feature/view4dfrom3d/view4dfrom3d';
import OverviewAnalysisMonthly from '../../feature/analysis/OverviewAnalysisMonthly';
import QuickDirect from '../../feature/QuickDirect/QuickDirect';
import LoginForm from '../../feature/LoginForm';
import { useStore } from '../store/store';
import { useEffect } from 'react';
import ModalContainer from '../Modal/ModalContainer';
import PatternHistory from '../../feature/history/PatternHistory';
import FourDNavBarMobile from './FourDNavBarMobile';
import DigitPositionHist from '../../feature/history/DigitPositionHistory/DigitPositionHist';
import IntervalHistory from '../../feature/history/IntervalHistory/IntervalHistory';
import UserProfile from '../../feature/profile/UserProfile';
import IntervalTop3Interval from '../../feature/history/IntervalHistory/IntervalTop3Interval';
import IntervalCombination3Top3History from '../../feature/history/History3D/IntervalCombination3Top3History';
import QueryByMonthDay from '../../feature/history/QueryNumbers/QueryByMonthDay';
import QuickGraph from '../../feature/QuickDirect/QuickGraph';
import QueryByInterval from '../../feature/history/QueryNumbers/QueryByInterval';
import QuickGraphTop3 from '../../feature/QuickDirect/QuickGraphTop3';
import AllRankHistory from '../../feature/history/Rank/AllRankHistory';
import Top3RankHistory from '../../feature/history/Rank/Top3RankHistory';
import MonthlyRankAll from '../../feature/history/Rank/MonthlyRankAll';
import Forecast from '../../feature/Forecast/Forecast';
import Best3D from '../../feature/analysis3d/Best3D';
import System3DRankHistory from '../../feature/history/History3D/System3DRankHistory';
import ResetPassword from '../../feature/ResetPassword';
import ViewForecastMethod from '../../feature/admin/ViewForecastMethod';
import analysis3dWithParams from '../../feature/analysis3d/analysis3dWithParams';
import analysis4DWithParams from '../../feature/analysis4d/analysis4DWithParams';
import analysis4DDirectWithParams from '../../feature/analysis4d/analysis4DDirectWithParams';
import analysis4DWithRank from '../../feature/analysis4d/analysis4DWithRank';
import view3dfrom2d from '../../feature/view4dfrom3d/view3dfrom2d';
import Best2D from '../../feature/analysis2d/Best2D';
import about from '../../feature/about';
import sum3dhistory from '../../feature/history/Sum3D/sum3dhistory';
import DetailResult from '../../feature/drawresult/DetailResult';
import DirectSquareBox from '../../feature/Forecast/DirectSquareBox';
import RatingHistory from '../../feature/history/Rating/RatingHistory';
import StatusHistory from '../../feature/history/Status/StatusHistory';
import WatchListEntry from '../../feature/WatchList/WatchListEntry';
import ScoringNumbers from '../../feature/history/QueryNumbers/ScoringNumbers';
import sum2dhistory from '../../feature/history/Sum2D/sum2dhistory';
import Sum4DNumberhistory from '../../feature/history/Sum/Sum4DNumberhistory';
import analysis4DWithSumRoot from '../../feature/analysis4d/analysis4DWithSumRoot';
import Sum4DNumberhistoryAll from '../../feature/history/Sum/Sum4DNumberhistoryAll';
import StatusHistory3D from '../../feature/history/Status/StatusHistory3D';
import analysis4DStatus from '../../feature/analysis4d/analysis4DStatus';
import AnalysisWithR from '../../feature/analysis4d/AnalysisWithR';
import EWinningBox from '../../feature/Forecast/EWinningBox';
import MaintenanceMode from '../../MaintenanceMode';


function App() {
 
  const location = useLocation();
  const {commonStore, userStore} = useStore();
  let marginTop = window.innerWidth <= 768 ? "7em" : "5em";

  if (!userStore.isLogged){
    marginTop = "3em";
  }

  const isBasic = userStore.isLogged && userStore.user?.groupID !== undefined && userStore.user.groupID > 1 ? false: true;

  useEffect(()=>{

    if (commonStore._token){
      userStore.getUser().finally(()=>{
        commonStore.setAppLoaded();
      });
    }
    else{
      commonStore.setAppLoaded();
    }
  },[commonStore, userStore]);

  
  const curYear = new Date().getFullYear();

  //if(!commonStore._appLoaded){
    //return (<LoadingComponent></LoadingComponent>);
    //}

    var curDateTime = new Date()
    var day = curDateTime.getDay()
    var hour = curDateTime.getHours()
    var minute = curDateTime.getMinutes();
    if (day === 0 || day === 6 || day === 3) {
        if (hour >= 22 && hour <= 23) {
            if (hour === 23 && minute <= 30) {
                return (<MaintenanceMode></MaintenanceMode>);
            }
            else {
                if (hour !== 23) {
                    return (<MaintenanceMode></MaintenanceMode>);
                }
            }
        }
    }


  return (
  <>
    <ModalContainer></ModalContainer>
    <ToastContainer position="bottom-right" hideProgressBar></ToastContainer>

    {window.innerWidth <= 768 ? <FourDNavBarMobile></FourDNavBarMobile>:
    <FourDNavBar></FourDNavBar>
    }
    <div style={{marginTop: marginTop, paddingBottom: 50}}>
        <Segment>
            <Switch>
            <Route path='/resetPassword/:id' component={ResetPassword}></Route>
            <Route exact path="/" component={HomePage}></Route>
            <Route exact path="/about" component={about}></Route>

            {!isBasic && <Route exact path="/ewinbox" component={DirectSquareBox}></Route>}
            {!isBasic && <Route exact path="/ewinbox2" component={EWinningBox}></Route>}
            
            {!isBasic && <Route exact path="/watchlist" component={WatchListEntry}></Route>}
            <Route exact path="/homepage" component={HomePage}></Route>
            <Route exact path="/drawresult" component={DetailResult}></Route>
            <Route exact path="/digitposition" component={digitposition}></Route>
            <Route exact path="/analysis" component={Analysis}></Route>
            {!isBasic && <Route exact path="/overviewbymonth" component={OverviewAnalysisMonthly}></Route>}
            <Route exact path="/analysisbymonth" component={AnalysisByMonth}></Route>
            <Route exact path="/analysis3D" component={Analysis3D}></Route>
            <Route exact path="/analysis2D" component={Analysis2D}></Route>
            <Route exact path="/quickdirect" component={QuickDirect}></Route>
            <Route exact path="/login" component={LoginForm}></Route>
            <Route exact path="/patternhistory" component={PatternHistory}></Route>
            {!isBasic && <Route exact path="/ratinghistory" component={RatingHistory}></Route>}
            {!isBasic && <Route exact path="/statushistory" component={StatusHistory}></Route>}
            {!isBasic && <Route exact path="/statusHistory3D" component={StatusHistory3D}></Route>}
            {!isBasic && <Route exact path="/digithistory" component={DigitPositionHist}></Route>}
            <Route exact path="/intervalhistory" component={IntervalHistory}></Route>
            <Route exact path="/profile" component={UserProfile}></Route>
            <Route exact path="/top3intervalhistory" component={IntervalTop3Interval}></Route>
            <Route exact path="/combination3top3intervalhistory" component={IntervalCombination3Top3History}></Route>
            <Route exact path="/querymonthday" component={QueryByMonthDay}></Route>
            <Route exact path="/quickgraph" component={QuickGraph}></Route>
            {!isBasic && <Route exact path="/querynumber" component={QueryByInterval}></Route>}
            {!isBasic && <Route exact path="/scorenumber" component={ScoringNumbers}></Route>}

            {!isBasic && <Route exact path="/quickgraphtop3" component={QuickGraphTop3}></Route>}
            {!isBasic && <Route exact path="/rankallhistory" component={AllRankHistory}></Route>}
            <Route exact path="/ranktop3history" component={Top3RankHistory}></Route>
            <Route exact path="/monthrankallhistory" component={MonthlyRankAll}></Route>
            <Route exact path="/forecast" component={Forecast}></Route>
            <Route exact path="/best3d" component={Best3D}></Route>
            <Route exact path="/best2d" component={Best2D}></Route>
            {!isBasic && <Route exact path="/result3DRank" component={System3DRankHistory}></Route>}
            <Route exact path="/viewForecastMethod" component={ViewForecastMethod}></Route>
            {!isBasic && <Route exact path="/sum3d" component={sum3dhistory}></Route>}
            {!isBasic && <Route exact path="/sum2d" component={sum2dhistory}></Route>}
            {!isBasic && <Route exact path="/sum4dNumber" component={Sum4DNumberhistory}></Route>}
            {!isBasic && <Route exact path="/sum4dNumber2D" component={Sum4DNumberhistoryAll}></Route>}
            {!isBasic && <Route exact path="/system4DWithR" component={AnalysisWithR}></Route>}

            <Route exact path='/3DAnalysisTypeAndRange/:type3D/:range3D/:iDrawType' component={analysis3dWithParams}></Route>
            <Route exact path='/4DAnalysisTypeAndRange/:type4D/:range4D/:iDrawType' component={analysis4DWithParams}></Route>
            <Route exact path='/4DAnalysisDirectTypeAndRange/:type4D/:range4D/:iDrawType' component={analysis4DDirectWithParams}></Route>
            <Route exact path='/4DAnalysisRankTypeAndRange/:type4D/:range4D/:iDrawType' component={analysis4DWithRank}></Route>
            <Route exact path='/4DAnalysisSumRoot/:type4D/:range4D/:iDrawType' component={analysis4DWithSumRoot}></Route>
            <Route exact path='/get4DByStatus/:statusNumber/:statusType/:iDrawType/:position3D' component={analysis4DStatus}></Route>
            
            
            
            <Route exact key={location.key} path={['/view4dfrom3d', '/search4dfrom3d/:id' ]} component={view4dfrom3d}></Route>
            <Route exact key={location.key} path={['/view', '/search/:id' ]} component={ViewEditNumber}></Route>
            <Route exact key={location.key} path={["/view3dfrom2d",'/search3dfrom2d/:id']} component={view3dfrom2d}></Route>

            <Route path="/server-error" component={ServerError}></Route>
            <Route component={NotFound}></Route>
            </Switch>
        </Segment>
    </div>
    <footer>
    <Segment textAlign='center' color='blue'>            
            Copyright © {curYear} All Rights Reserved - 4DGrandMaster.com
    </Segment>
    </footer>
    </>
  );
}

export default observer(App);